import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import ThreeGlobe from 'three-globe';
import geoJson from './geoData.json';
import dataParticles from './dataParticles.json';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from './unrealBloomWithAlpha';

const state = {
    canvas: null,
    sizes: {
        width: window.innerWidth,
        height: window.innerHeight
    },
    controls: null,

    // Tick
    clock: new THREE.Clock(),
    previousTime: -Infinity,

    particlesMesh: new THREE.Points(),
    scene: new THREE.Scene(),

    spawnpoints: [],

    bloomPassRef: null,
    bloomParams: {
        exposure: 1,
        bloomStrength: 1.8,
        bloomThreshold: 0,
        bloomRadius: 0
    },
    composer: null
}

init();
function init() {

    // Canvas
    state.canvas = document.querySelector('canvas.webgl')

    // Rotate the scene
    state.scene.rotation.z = 0.2;

    /**
    * Lights
    */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
    state.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.camera.left = - 7
    directionalLight.shadow.camera.top = 7
    directionalLight.shadow.camera.right = 7
    directionalLight.shadow.camera.bottom = - 7
    directionalLight.position.set(- 5, 5, 0)
    state.scene.add(directionalLight)

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(75, state.sizes.width / state.sizes.height, 0.1, 1000)
    state.camera = camera;
    camera.position.set(2, 100, 210)
    state.scene.add(camera)

    // Controls
    state.controls = new OrbitControls(camera, state.canvas)
    state.controls.target.set(0, 0.75, 0)
    state.controls.enableDamping = true
    state.controls.enabled = false;


    /**
     * Renderer
     */
    state.renderer = new THREE.WebGLRenderer({
        canvas: state.canvas,
        alpha: true
    })
    state.renderer.setClearColor(0x000000, 0);
    state.renderer.shadowMap.enabled = true
    state.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    state.renderer.setSize(state.sizes.width, state.sizes.height)
    state.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    const renderScene = new RenderPass(state.scene, camera);
    state.composer = new EffectComposer(state.renderer);
    state.composer.addPass(renderScene,);

    initBloomPass();


    // Init resizing
    window.addEventListener('resize', () => {
        // Update sizes
        state.sizes.width = window.innerWidth;
        state.sizes.height = window.innerHeight;

        // Update camera
        camera.aspect = state.sizes.width / state.sizes.height;
        camera.updateProjectionMatrix()

        // Update renderer
        state.renderer.setSize(state.sizes.width, state.sizes.height);
        state.composer.setSize(state.sizes.width, state.sizes.height);
        state.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        initBloomPass();
    })


    initGlobe();
    initAtmosphereParticles();

    // Start anim loop
    tick();
}

function initBloomPass() {
    if (state.bloomPassRef) {
        state.composer.removePass(state.bloomPassRef);
        state.bloomPassRef = null;
    }
    state.bloomPassRef = new UnrealBloomPass(new THREE.Vector2(state.sizes.width, state.sizes.height), state.bloomParams.bloomStrength, state.bloomParams.bloomRadius, state.bloomParams.bloomThreshold);
    state.bloomPassRef.threshold = state.bloomParams.bloomThreshold;
    state.bloomPassRef.strength = state.bloomParams.bloomStrength;
    state.bloomPassRef.radius = state.bloomParams.bloomRadius;

    state.composer.addPass(state.bloomPassRef);
}





function tick() {
    const elapsedTime = state.clock.getElapsedTime()
    const deltaTime = elapsedTime - state.previousTime;
    state.previousTime = elapsedTime

    // Update controls
    state.controls.update();

    // Rotate the globe and particles mesh

    if (state.Globe) {
        state.Globe.rotation.y += 0.001;
    }

    if (state.particlesMesh) {
        state.particlesMesh.rotation.y += 0.001;
    }


    // Animate the particles geometry
    if (state.particlesGeometry) {
        state.particlesGeometry.dispose();
        const positions = state.particlesGeometry.getAttribute('position');
        const posArray = new Float32Array(positions.count * 3);
        const speed = 0.0005;
        const maxDistance = 120;

        let i = 0;
        for (const spawnpoint of state.spawnpoints) {
            posArray[i] = positions.array[i] + speed * positions.array[i];
            posArray[i + 1] = positions.array[i + 1] + speed * positions.array[i + 1];
            posArray[i + 2] = positions.array[i + 2] + speed * positions.array[i + 2];

            const posVector = new THREE.Vector3(posArray[i], posArray[i + 1], posArray[i + 2]);

            if (posVector.length() > maxDistance) {
                resetToSpawnpoint(i, spawnpoint, posArray);
            }

            i += 3;
        }

        state.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
    }


    // Render
    state.composer.render();
    //state.renderer.render(state.scene, state.camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}


/**
 * Initialize the globe
 */
function initGlobe() {
    // Gen random data
    const N = 50;

    const arcsData = [...Array(N).keys()].map(() => ({
        startLat: (Math.random() - 0.5) * 180,
        startLng: (Math.random() - 0.5) * 360,
        endLat: (Math.random() - 0.5) * 180,
        endLng: (Math.random() - 0.5) * 360,
        color: 'rgba(177, 221, 234, 0.3)' //['rgba(255,255,255,0.3)', 'rgba(57, 61, 158, 0.3)', 'rgba(93, 149, 213,.3)', 'rgba(52, 77, 168,.3)'][Math.round(Math.random() * 3)]
    }));
    const Globe = new ThreeGlobe()
        // atmosphere
        .atmosphereColor('#6AA7DC')
        .globeImageUrl('/assets/earth.png')
        // hex polygon
        .arcsData(arcsData)
        .arcColor('color')
        .arcDashLength(0.4)
        .arcDashGap(4)
        .arcDashInitialGap(() => Math.random() * 5)
        .arcDashAnimateTime(1000);

    const globeMaterial = Globe.globeMaterial();
    globeMaterial.transparent = true;
    globeMaterial.opacity = 0.5;
    state.scene.add(Globe);
    state.Globe = Globe;
}

/**
 * Intiialize the atmospheric particles
 */
function initAtmosphereParticles() {
    const polygonData = geoJson.features;

    // Create spawnpoints from the country polygons
    for (const feature of polygonData) {
        for (const coords of feature.geometry.coordinates) {
            for (const coord of coords) {
                let lng = coord[0];
                let lat = coord[1];
                state.spawnpoints.push(state.Globe.getCoords(lat, lng, 0));
            }

        }
    }
    const particlesGeometry = new THREE.BufferGeometry;
    const particlesMat = new THREE.PointsMaterial({ size: 0.5, transparent: true, color: '#82C7DB' });
    const posArray = new Float32Array((state.spawnpoints.length - 1) * 3);

    // Save to state
    state.particlesGeometry = particlesGeometry;

    // Spawn the particles
    let i = 0;
    for (const spawnpoint of state.spawnpoints) {
        resetToSpawnpoint(i, spawnpoint, posArray)
        i += 3;
    }


    // Create  the geometry and buffer, mesh
    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
    state.particlesMesh = new THREE.Points(particlesGeometry, particlesMat)
    state.scene.add(state.particlesMesh);
}

/**
 * Reset vertice to spawnpoint
 * @param {*} i index in the loop
 * @param {*} spawnpoint the spawnpoint
 * @param {*} posArray the array with positions
 */
function resetToSpawnpoint(i, spawnpoint, posArray) {
    const spawnOffset = 10;
    posArray[i] = spawnpoint.x + Math.random() * spawnOffset - spawnOffset / 2 || 0;
    posArray[i + 1] = spawnpoint.y + Math.random() * spawnOffset - spawnOffset / 2 || 0;
    posArray[i + 2] = spawnpoint.z + Math.random() * spawnOffset - spawnOffset / 2 || 0;
}

